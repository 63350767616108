import React, { useRef, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";

const Text = "Delete";

const Confirm = "Confirm";

const DeleteButton = ({
	onDelete,
	text = Text,
	confirm = Confirm,
	...props
}) => {
	const [isConfirming, setConfirming] = useState(false);
	const buttonRef = useRef();
	const timeoutRef = useRef();

	const handleClick = () => {
		if (isConfirming) {
			onDelete();
		} else {
			setConfirming(true);
		}
	};

	useEffect(() => {
		if (isConfirming) {
			const timeout = setTimeout(() => {
				setConfirming(false);
				buttonRef.current.blur();
			}, 3000);

			timeoutRef.current = timeout;
		}

		return () => {
			clearTimeout(timeoutRef.current);
		};
	}, [isConfirming]);

	return (
		<Button
			ref={buttonRef}
			onClick={handleClick}
			variant={isConfirming ? "danger" : "outline-danger"}
			{...props}
		>
			{isConfirming ? confirm : text}
		</Button>
	);
};

DeleteButton.propTypes = {};

export default DeleteButton;
