import React from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Spinner from "react-bootstrap/Spinner";

import styled from "styled-components";
import { PlayFill, PauseFill } from "react-bootstrap-icons";
import { SCENE_STATUS } from "../../constants";
import { formatConstant, getSceneIsBusy } from "../../utils";
import useAppState from "../../hooks/useState";
import usePlayerContext from "../../hooks/usePlayerContext";
import Visualiser from "../Visualiser";
import ProgressRing from "../ProgressRing";

const Container = styled.div`
	${({ theme }) => theme.components.scenePreview.container};
`;

const Details = styled.div`
	${({ theme }) => theme.components.scenePreview.details};
`;

const Title = styled.h4`
	${({ theme }) => theme.components.scenePreview.title};
`;

const Subtitle = styled.small`
	${({ theme }) => theme.components.scenePreview.subtitle};
`;

const PlayButtonWrapper = styled.div`
	${({ theme }) => theme.components.scenePreview.playButtonWrapper};
`;

const PlayPauseButton = styled(Button)`
	${({ theme }) => theme.components.scenePreview.playPauseButton};
`;

const ButtonContainer = styled.div`
	${({ theme }) => theme.components.scenePreview.buttonContainer};
`;

const StyledSpinner = styled(Spinner)`
	height: 50%;
	width: 50%;
	border-width: 5px;
`;

const getSubtitleString = (clips, isPreset, status, loading) => {
	const str = [];

	if (isPreset) {
		str.push("Preset");
	} else {
		str.push("Custom");
		str.push(
			clips.length === 0
				? "No clips"
				: clips.length === 1
				? "1 clip"
				: `${clips.length} clips`
		);
	}

	if (clips.length > 0) {
		if (loading) {
			str.push("Loading...");
		} else {
			str.push(formatConstant(status));
		}
	}

	return str.join(" &bull; ");
};

const ScenePreview = ({ id, name, clips, isPreset, path, status }) => {
	const {
		state: {
			options: { fadeDuration },
		},
	} = useAppState();

	const history = useHistory();
	const { play, pause, busy } = usePlayerContext();
	const sceneIsBusy = getSceneIsBusy(status);

	const startProgress =
		status === SCENE_STATUS.FADE_IN || status === SCENE_STATUS.PLAYING
			? 100
			: 0;

	return (
		<Container>
			<Details>
				<Title>
					{name}
					<Visualiser
						show={
							status === SCENE_STATUS.FADE_IN ||
							status === SCENE_STATUS.PLAYING
						}
						transitionDuration={fadeDuration}
					/>
				</Title>
				<Subtitle
					dangerouslySetInnerHTML={{
						__html: getSubtitleString(clips, isPreset, status),
					}}
				/>
				<ButtonContainer>
					<ButtonGroup aria-label="scene controls">
						<Button
							variant="outline-secondary"
							onClick={() => history.push(`/scene/${path}`)}
						>
							Edit
						</Button>
					</ButtonGroup>
				</ButtonContainer>
			</Details>
			<PlayButtonWrapper>
				<ProgressRing
					progress={startProgress}
					fadeDuration={fadeDuration}
				/>
				<PlayPauseButton
					$active={clips.length > 0 && !sceneIsBusy && !busy}
					variant={
						status === SCENE_STATUS.INIT ||
						status === SCENE_STATUS.LOADING
							? "secondary"
							: status === SCENE_STATUS.READY
							? "success"
							: "primary"
					}
					disabled={clips.length === 0 || sceneIsBusy || busy}
					onClick={() => {
						if (status === SCENE_STATUS.READY) {
							play(id);
						}
						if (status === SCENE_STATUS.PLAYING) {
							pause(id);
						}
					}}
				>
					{status === SCENE_STATUS.INIT && (
						<StyledSpinner animation="border" variant="light" />
					)}
					{status === SCENE_STATUS.LOADING && (
						<StyledSpinner animation="border" variant="light" />
					)}
					{status === SCENE_STATUS.READY && <PlayFill />}
					{status === SCENE_STATUS.PLAYING && <PauseFill />}
					{status === SCENE_STATUS.FADE_IN && <PauseFill />}
					{status === SCENE_STATUS.FADE_OUT && <PauseFill />}
					{status === SCENE_STATUS.QUEUED && <PlayFill />}
				</PlayPauseButton>
			</PlayButtonWrapper>
		</Container>
	);
};

ScenePreview.propTypes = {};

export default ScenePreview;
