const raf = function (func) {
	setTimeout(func, 16);
};

function ease(options) {
	const startValue = "startValue" in options ? options.startValue : 0;
	const endValue = "endValue" in options ? options.endValue : 1;
	const durationMs = "durationMs" in options ? options.durationMs : 200;
	const onComplete = options.onComplete || function () {};

	const stepCount = durationMs / 16;
	const valueIncrement = (endValue - startValue) / stepCount;
	const sinValueIncrement = Math.PI / stepCount;

	let currentValue = startValue;
	let currentSinValue = 0;

	function step() {
		currentSinValue += sinValueIncrement;
		currentValue +=
			valueIncrement * Math.pow(Math.sin(currentSinValue), 2) * 2;

		if (currentSinValue < Math.PI) {
			options.onStep(currentValue);
			raf(step);
		} else {
			options.onStep(endValue);
			onComplete();
		}
	}

	raf(step);
}

export default ease;
