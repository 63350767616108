const colour = {
	background: {
		light: "#f3f3f3",
		medium: "#ccc",
		dark: "#333",
	},
	border: {
		light: "#f3f3f3",
		medium: "#d1d1d1",
		dark: "#333",
	},
	text: {
		light: "#f3f3f3",
		medium: "#ccc",
		dark: "#333",
	},
	error: "#dc3545",
};

export default colour;
