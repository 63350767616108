import birds from "./export/birds.mp3";
import blacksmith from "./export/blacksmith.mp3";
import bonfire from "./export/bonfire.mp3";
import cicadas from "./export/cicadas.mp3";
import crickets from "./export/crickets.mp3";
import chicken from "./export/chicken.mp3";
import foxShrieking from "./export/fox_shrieking.mp3";
import leaves from "./export/leaves.mp3";
import market from "./export/market.mp3";
import ocean from "./export/ocean.mp3";
import rain from "./export/rain.mp3";
import seagulls from "./export/seagulls.mp3";
import stream from "./export/stream.mp3";
import wind from "./export/wind.mp3";
import windchimes from "./export/windchimes.mp3";
import caveDrips from "./export/cave_drips.mp3";
import campfire from "./export/campfire.mp3";
import owls from "./export/owls.mp3";
import thunder from "./export/thunder.mp3";
import woodCreaking from "./export/wood_creaking.mp3";

import * as tags from "../../constants/tags";

export const Birds = {
	name: "Birds",
	icon: "🦜",
	file: birds,
	tags: [tags.birds, tags.day, tags.forest],
	credit: {
		user: "johnaudiotech",
		name: "Bird song in the woods",
		url: "https://freesound.org/s/346621/",
	},
};

export const Blacksmith = {
	name: "Blacksmith",
	icon: "⚒️",
	file: blacksmith,
	tags: [tags.tools],
	credit: {
		user: "Emmaproductions",
		name: "By the blacksmith-001.wav",
		url: "https://freesound.org/s/254371/",
	},
};

export const Bonfire = {
	name: "Bonfire",
	icon: "🔥",
	file: bonfire,
	tags: [tags.fire, tags.camp],
	credit: {
		user: "Dynamicell",
		name: "fire_embers_large_campfire.aif",
		url: "https://freesound.org/s/17554/",
	},
};

export const Cicadas = {
	name: "Cicadas",
	icon: "🐞",
	file: cicadas,
	tags: [tags.night, tags.insects],
	credit: {
		user: "johnaudiotech",
		name: "Crickets in Manistee forest in Michigan",
		url: "https://freesound.org/s/347049/",
	},
};

export const Crickets = {
	name: "Crickets",
	icon: "🦗",
	file: crickets,
	tags: [tags.night, tags.insects],
	credit: {
		user: "dekker462",
		name: "Crickets",
		url: "https://freesound.org/s/436510/",
	},
};

export const Chicken = {
	name: "Chicken",
	icon: "🐔",
	file: chicken,
	tags: [tags.chickens, tags.cows],
	credit: {
		user: "acclivity",
		name: "BerberFarmyard2.wav",
		url: "https://freesound.org/s/18074/",
	},
};

export const Fox_Shrieking = {
	name: "Fox (Shriek)",
	icon: "🦊",
	file: foxShrieking,
	tags: [tags.foxes, tags.creepy],
	credit: {
		user: "craig.cerinocraigsays",
		name: "Fox screaming in the night.mp3",
		url: "https://freesound.org/s/537586/",
	},
};

export const Leaves = {
	name: "Leaves",
	icon: "🍃",
	file: leaves,
	tags: [tags.leaves],
	credit: {
		user: "juskiddink",
		name: "Aspen tree in strong wind.wav",
		url: "https://freesound.org/s/78955/",
	},
};

export const Market = {
	name: "Market",
	icon: "🧺",
	file: market,
	tags: [tags.town, tags.people],
	credit: {
		user: "le_abbaye_Noirlac",
		name: "Market 2.aif",
		url: "https://freesound.org/s/129677/",
	},
};

export const Waves = {
	name: "Waves",
	icon: "🌊",
	file: ocean,
	tags: [tags.ocean, tags.seabirds],
	credit: {
		user: "Eelke",
		name: "rough inconsistent waves",
		url: "https://freesound.org/s/462594/",
	},
};

export const Rain = {
	name: "Rain",
	icon: "☔️",
	file: rain,
	tags: [tags.rain, tags.forest],
};

export const Seagulls = {
	name: "Seagulls",
	icon: "🐦",
	file: seagulls,
	tags: [tags.seabirds],
	credit: {
		user: "squashy555",
		name: "Seagull on beach",
		url: "https://freesound.org/s/353416/",
	},
};

export const Stream = {
	name: "Stream",
	icon: "💦",
	file: stream,
	tags: [tags.river],
	credit: {
		user: "johnaudiotech",
		name: "Babbling Brook",
		url: "https://freesound.org/s/346622/",
	},
};

export const Wind = {
	name: "Wind",
	icon: "💨",
	file: wind,
	tags: [tags.wind],
};

export const Cave = {
	name: "Drips",
	icon: "💧",
	file: caveDrips,
	tags: [],
	credit: {
		user: "everythingsounds",
		name: "Cave Drips",
		url: "https://freesound.org/s/199515/",
	},
};

export const Windchimes = {
	name: "Chimes",
	icon: "🎐",
	file: windchimes,
	tags: [],
	credit: {
		user: "mc2method",
		name: "Windchime Combination",
		url: "https://freesound.org/s/196013/",
	},
};

export const Campfire = {
	name: "Campfire",
	icon: "🔥",
	file: campfire,
	tags: [],
	credit: {
		user: "inchadney",
		name: "Campfire.WAV",
		url: "https://freesound.org/s/262450/",
	},
};

export const Owls = {
	name: "Owls",
	icon: "🦉",
	file: owls,
	tags: [],
	credit: {
		user: "Benboncan",
		name: "Tawny Owls 2.wav",
		url: "https://freesound.org/s/64544/",
	},
};

export const Thunder = {
	name: "Thunder",
	icon: "🌩",
	file: thunder,
	tags: [],
	credit: {
		user: "hifijohn",
		name: "thunder",
		url: "https://freesound.org/s/242735/",
	},
};

export const Wood_Creaking = {
	name: "Wood (Creaking)",
	icon: "🚪",
	file: woodCreaking,
	tags: [],
	credit: {
		user: "PimFeijen",
		name: "LargeWoodenShip.mp3",
		url: "https://freesound.org/s/195193/",
	},
};

export const all = [
	Birds,
	Blacksmith,
	Bonfire,
	Cave,
	Campfire,
	Chicken,
	Cicadas,
	Crickets,
	Fox_Shrieking,
	Leaves,
	Market,
	Owls,
	Rain,
	Seagulls,
	Stream,
	Thunder,
	Waves,
	Wind,
	Windchimes,
	Wood_Creaking,
];
