import colour from "./colour";
import components from "./components";
import pages from "./pages";
import space from "./space";
import { mediaQueries, breakpoints } from "./breakpoints";

const theme = {
	constants: {
		sidebar: {
			mobile: "3.4rem",
			tablet: "100px",
		},
	},
	space,
	breakpoints,
	colour,
	components,
	pages,
	device: mediaQueries,
};

export { default as GlobalStyle } from "./globalStyles";
export default theme;
