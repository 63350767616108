import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Scene from "../Scene";
import styled from "styled-components";
import Menu from "../Menu/Menu";
import AddClipModal from "../../modals/AddClip";
import NewSceneModal from "../../modals/NewScene";
import useAppState from "../../hooks/useState";
import { MODAL_TYPE, ROUTE, SCENE_STATUS, CLIP_STATUS } from "../../constants";
import usePlayer from "../../hooks/usePlayer";
import ControlBar from "../ControlBar";
import Sidebar from "../Sidebar";
import About from "../../pages/About";
import Feedback from "../../pages/Feedback";
import Options from "../../pages/Options";
import { loadFiles } from "../../utils";
import { Context } from "../../hooks/usePlayerContext";

const StyledMain = styled.main`
	${({ theme }) => theme.components.main}
`;

const Main = () => {
	const { state, selectors, actions } = useAppState();
	const { scenes, activeModal, showSidebar } = state;
	const { getScene } = selectors;
	const { toggleSidebar, setSceneStatus, setClipStatus } = actions;

	const player = usePlayer();

	const loadScene = async (id) => {
		const scene = getScene(state, id);

		if (scene.status === SCENE_STATUS.INIT) {
			setSceneStatus(id, SCENE_STATUS.LOADING);

			scene.clips.forEach((clip) => {
				setClipStatus(clip.id, CLIP_STATUS.LOADING);
			});

			await loadFiles(scene.clips);

			scene.clips.forEach((clip) => {
				setClipStatus(clip.id, CLIP_STATUS.READY);
			});

			setSceneStatus(id, SCENE_STATUS.READY);
		}
	};

	// preload scenes as they're added
	useEffect(() => {
		Object.keys(scenes).forEach((id) => {
			const scene = scenes[id];
			if (scene.status === SCENE_STATUS.INIT) {
				loadScene(id);
			}
		});
	}, [scenes]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Context.Provider value={player}>
			<StyledMain>
				<Switch>
					<Route
						path={ROUTE.scene.path}
						render={({ match }) => {
							const scene = selectors.getSceneByPath(
								state,
								match.params.path
							);
							return scene ? (
								<Scene {...scene} />
							) : (
								<Redirect to={ROUTE.home.path} />
							);
						}}
					/>
					<Route exact path={ROUTE.home.path}>
						<Menu />
					</Route>
					<Route exact path={ROUTE.about.path} component={About} />
					<Route
						exact
						path={ROUTE.feedback.path}
						component={Feedback}
					/>
					<Route
						exact
						path={ROUTE.options.path}
						component={Options}
					/>
					<Redirect to={ROUTE.home.path} />
				</Switch>
			</StyledMain>
			<ControlBar />
			<Sidebar show={showSidebar} toggleSidebar={toggleSidebar} />
			{activeModal === MODAL_TYPE.ADD_CLIP && <AddClipModal />}
			{activeModal === MODAL_TYPE.NEW_SCENE && <NewSceneModal />}
		</Context.Provider>
	);
};

Main.propTypes = {};

export default Main;
