import styled from "styled-components";
import { ReactComponent as VisualiserIcon } from "../../assets/image/audio.svg";
import useAppState from "../../hooks/useState";

const StyledVisualiserIcon = styled(VisualiserIcon)`
	${({ theme }) => theme.components.visualiser};
`;

const Visualiser = ({ show, scale = 1, opacity = 1 }) => {
	const {
		state: {
			options: { fadeDuration },
		},
	} = useAppState();

	return (
		<StyledVisualiserIcon
			$scale={scale}
			$show={show}
			$opacity={opacity}
			$transitionDuration={fadeDuration}
		/>
	);
};

export default Visualiser;
