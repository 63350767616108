import React from "react";
import { useHistory } from "react-router-dom";
import { ROUTE } from "../../constants";
import styled from "styled-components";
import Button from "react-bootstrap/Button";

const Container = styled.div`
	${({ theme }) => theme.components.container}
`;

const Content = styled.div`
	padding: ${({ theme }) => theme.space.sm};
`;

export const BackButton = () => {
	const { push } = useHistory();

	return (
		<Button variant="link" href="#" onClick={() => push(ROUTE.home.path)}>
			&larr; Back
		</Button>
	);
};

const Page = ({ backButton = <BackButton />, children, ...props }) => (
	<Container>
		<div>{backButton}</div>
		<Content>{children}</Content>
	</Container>
);

Page.propTypes = {};

export default Page;
