import * as clips from "../assets/audio";

export const animals = {
	name: "Animals",
	icon: "🐮",
	clips: [
		clips.Birds,
		clips.Chicken,
		clips.Fox_Shrieking,
		clips.Owls,
		clips.Seagulls,
	],
};

export const insects = {
	name: "Insects",
	icon: "🐞",
	clips: [clips.Cicadas, clips.Crickets],
};

export const weather = {
	name: "Weather",
	icon: "⛅️",
	clips: [clips.Rain, clips.Thunder, clips.Wind],
};

export const environment = {
	name: "Environmental",
	icon: "🍃",
	clips: [
		clips.Cave,
		clips.Leaves,
		clips.Waves,
		clips.Stream,
		clips.Wood_Creaking,
	],
};

export const social = {
	name: "Social",
	icon: "👥",
	clips: [clips.Bonfire, clips.Campfire, clips.Market],
};

export const commerce = {
	name: "Commerce",
	icon: "💰",
	clips: [clips.Blacksmith, clips.Market],
};

export const Instruments = {
	name: "Instruments",
	icon: "🎵",
	clips: [clips.Windchimes],
};

export const Creepy = {
	name: "Creepy",
	icon: "👻",
	clips: [clips.Wood_Creaking, clips.Owls, clips.Fox_Shrieking],
};
