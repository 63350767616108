import {
	LOCAL_STORAGE_KEY,
	SCENE_STATUS,
	CLIP_STATUS,
} from "../constants/index";

const initialState = {
	version: "0.0.5",
	activeModal: null,
	scenes: {},
	clips: {},
	masterVolume: 1.0,
	showSidebar: false,
	options: {
		fadeDuration: 5000,
		debug: false,
		crossfade: true,
	},
};

export const initialise = () => {
	try {
		const storedState =
			JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};

		// if version mismatch between scenes in localStorage and initialState, clear localStorage scenes
		if (storedState.version !== initialState.version) {
			console.warn(
				`Version mismatch detected. Local: ${storedState.version} vs Latest: ${initialState.version}. Clearing localStorage.`
			);
			localStorage.removeItem(LOCAL_STORAGE_KEY);
			return initialState;
		}

		// reset all scene statuses to INIT
		if (storedState.scenes) {
			Object.keys(storedState.scenes).forEach((id) => {
				storedState.scenes[id].status = SCENE_STATUS.INIT;
			});
		}

		// reset all clip statuses to INIT
		if (storedState.clips) {
			Object.keys(storedState.clips).forEach((id) => {
				storedState.clips[id].status = CLIP_STATUS.INIT;
			});
		}

		return {
			...initialState,
			...storedState,
		};
	} catch (error) {
		console.error("Unable to load from localStorage.", error);
		localStorage.removeItem(LOCAL_STORAGE_KEY);
		return initialState;
	}
};

export const clearLocalStorage = () => {
	localStorage.removeItem(LOCAL_STORAGE_KEY);
};

export const updateLocalStorage = (state) => {
	const { version, scenes, clips, options } = state;
	localStorage.setItem(
		LOCAL_STORAGE_KEY,
		JSON.stringify({ ...initialState, version, scenes, clips, options })
	);
};
