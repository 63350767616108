import React from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ROUTE } from "../../constants";

const Container = styled.aside`
	${({ theme }) => theme.components.sidebar.container}
`;

const Link = ({ route, onClick, children }) => {
	const history = useHistory();
	const routeMatch = useRouteMatch(route);

	return (
		<Button
			size="lg"
			disabled={routeMatch?.isExact}
			variant="link"
			href="#"
			onClick={() => {
				onClick();
				history.push(route);
			}}
		>
			{children}
		</Button>
	);
};

const Sidebar = ({ show, toggleSidebar }) => {
	return (
		<Container $show={show}>
			<Link onClick={toggleSidebar} route={ROUTE.home.path}>
				Home
			</Link>
			<Link onClick={toggleSidebar} route={ROUTE.about.path}>
				About
			</Link>
			<Link onClick={toggleSidebar} route={ROUTE.feedback.path}>
				Feedback
			</Link>
			<Link onClick={toggleSidebar} route={ROUTE.options.path}>
				Options
			</Link>
		</Container>
	);
};

Sidebar.propTypes = {};

export default Sidebar;
