import React from "react";
import styled from "styled-components";

const Svg = styled.svg`
	position: absolute;
	z-index: 100;
	pointer-events: none;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%) scale(1.05);
	height: 100%;
	width: 100%;
	color: #fff;
	opacity: 0.67;
`;

const Circle = styled.circle`
	transition: stroke-dashoffset ${({ $fadeDuration }) => $fadeDuration}ms;
	transition-timing-function: ease;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
`;

const ProgressRing = ({ progress = 0, fadeDuration }) => {
	const normalizedRadius = 45;
	const circumference = normalizedRadius * 2 * Math.PI;
	const strokeDashoffset = circumference - (progress / 100) * circumference;

	return (
		<Svg viewBox={`0 0 100 100`}>
			<Circle
				cx="50"
				cy="50"
				r={normalizedRadius}
				$fadeDuration={fadeDuration}
				stroke="currentColor"
				fill="none"
				strokeWidth="10"
				strokeDasharray={circumference + " " + circumference}
				style={{ strokeDashoffset }}
			/>
		</Svg>
	);
};

ProgressRing.propTypes = {};

export default ProgressRing;
