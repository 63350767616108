const breakpoints = ["0em", "48em", "64em", "90em"];

breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];

const mediaQueries = {
	tablet: `@media screen and (min-width: ${breakpoints[1]})`,
	desktop: `@media screen and (min-width: ${breakpoints[2]})`,
	large: `@media screen and (min-width: ${breakpoints[3]})`,
};

export { breakpoints, mediaQueries };
