import React, { useReducer, useEffect } from "react";

import { initialise, updateLocalStorage } from "./store";
import * as actions from "./actions";
import reducer from "./reducer";
import * as selectors from "./selectors";
import { Context } from "../hooks/useState";

const wrapActions = (dispatch) =>
	Object.keys(actions).reduce((acc, key) => {
		const action = actions[key];
		const wrappedAction = (...args) => dispatch(action(...args));
		acc[key] = wrappedAction;
		return acc;
	}, {});

const State = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, null, initialise);

	useEffect(() => {
		updateLocalStorage(state);
	}, [state]);

	return (
		<Context.Provider
			value={{
				state,
				actions: wrapActions(dispatch),
				selectors,
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default State;
