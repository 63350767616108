import { css } from "styled-components";

const container = ({ theme, $show }) => css`
	position: fixed;
	bottom: ${theme.constants.sidebar.mobile};
	left: 0;
	height: auto;
	width: 100%;
	transition: transform 400ms ease;
	will-change: top;
	transform: translateY(${$show ? "0" : "100%"});
	background-color: ${theme.colour.background.light};
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: stretch;
	overflow-y: scroll;
	padding: ${theme.space.sm};
	border-top: 1px solid ${theme.colour.border.medium};
`;

const styles = {
	container,
};

export default styles;
