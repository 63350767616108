import { v4 } from "uuid";

import types from "./types";
import { sanitiseSceneName } from "../utils";
import { CLIP_STATUS, SCENE_STATUS } from "../constants";

const createClip = ({ name, icon, file, volume = 1, tags }) => ({
	name,
	icon,
	file,
	volume,
	tags,
	status: CLIP_STATUS.INIT,
});

const createScene = ({
	name = "New Scene",
	clips = [],
	isPreset = false,
	tags,
}) => {
	const newClips = clips.reduce(
		(acc, clip) => ({
			...acc,
			[v4()]: createClip(clip),
		}),
		{}
	);

	return {
		newSceneId: v4(),
		newScene: {
			name,
			path: sanitiseSceneName(name),
			clips: Object.keys(newClips),
			isPreset,
			tags,
			status: SCENE_STATUS.INIT,
		},
		newClips: newClips,
	};
};

const deleteScene = (scenes, id) => {
	const clone = {
		...scenes,
	};
	delete clone[id];
	return clone;
};

const deleteClips = (clips, ids) => {
	const clone = {
		...clips,
	};

	ids.forEach((id) => delete clone[id]);

	return clone;
};

const deleteClip = (clips, id) => {
	const clone = {
		...clips,
	};
	delete clone[id];
	return clone;
};

const reducer = (state, { type, ...payload }) => {
	switch (type) {
		case types.ADD_SCENE:
			const { newSceneId, newScene, newClips } = createScene(payload);
			return {
				...state,
				scenes: {
					...state.scenes,
					[newSceneId]: {
						...newScene,
					},
				},
				clips: {
					...state.clips,
					...newClips,
				},
			};

		case types.REMOVE_SCENE:
			return {
				...state,
				scenes: deleteScene(state.scenes, payload.id),
				clips: deleteClips(state.clips, state.scenes[payload.id].clips),
			};

		case types.SAVE_SCENE:
			return {
				...state,
				scenes: {
					...state.scenes,
					[payload.id]: {
						...state.scenes[payload.id],
						name: payload.name,
						path: sanitiseSceneName(payload.name),
						isPreset: false,
					},
				},
			};

		case types.SET_SCENE_STATUS:
			if (Object.keys(state.scenes).length === 0) {
				// noop
				return state;
			}

			if (!state.scenes[payload.id]) {
				return state;
			}

			return {
				...state,
				scenes: {
					...state.scenes,
					[payload.id]: {
						...state.scenes[payload.id],
						status: payload.status,
					},
				},
			};

		case types.ADD_CLIP:
			const newClipId = v4();
			const newClip = createClip(payload.sound);

			return {
				...state,
				scenes: {
					...state.scenes,
					[payload.sceneId]: {
						...state.scenes[payload.sceneId],
						clips: [
							...state.scenes[payload.sceneId].clips,
							newClipId,
						],
						isPreset: false,
					},
				},
				clips: {
					...state.clips,
					[newClipId]: newClip,
				},
			};

		case types.REMOVE_CLIP:
			return {
				...state,
				scenes: {
					...state.scenes,
					[payload.sceneId]: {
						...state.scenes[payload.sceneId],
						clips: state.scenes[payload.sceneId].clips.filter(
							(id) => id !== payload.clipId
						),
						isPreset: false,
					},
				},
				clips: deleteClip(state.clips, payload.clipId),
			};

		case types.SET_CLIP_VOLUME:
			return {
				...state,
				clips: {
					...state.clips,
					[payload.clipId]: {
						...state.clips[payload.clipId],
						volume: payload.volume,
					},
				},
			};

		case types.SET_CLIP_STATUS:
			if (Object.keys(state.clips).length === 0) {
				// noop
				return state;
			}

			if (!state.clips[payload.id]) {
				return state;
			}

			return {
				...state,
				clips: {
					...state.clips,
					[payload.id]: {
						...state.clips[payload.id],
						status: payload.status,
					},
				},
			};

		case types.OPEN_MODAL:
			return {
				...state,
				activeModal: payload.modal,
			};

		case types.CLOSE_MODAL:
			return {
				...state,
				activeModal: null,
			};

		case types.SET_MASTER_VOLUME:
			return {
				...state,
				masterVolume: payload.volume,
			};

		case types.TOGGLE_SIDEBAR:
			return {
				...state,
				showSidebar: !state.showSidebar,
			};

		case types.SET_OPTION:
			return {
				...state,
				options: {
					...state.options,
					[payload.key]: payload.value,
				},
			};

		default:
			throw new Error(`Dispatch: payload type "${type}" went unhandled.`);
	}
};

export default reducer;
