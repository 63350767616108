import React from "react";
import Page from "../../components/Page";
import { SOUND } from "../../constants/";

const About = () => {
	return (
		<Page>
			<h1>Welcome, traveller!&nbsp;</h1>
			<br />
			<p>
				<strong>bard.tools</strong> aims to be a simple and easy way to
				create ambience for your tabletop sessions. Choose from pre-made
				scenes to get started quickly, or create and customise your own
				using a curated bank of sounds and effects.
			</p>
			<p>
				As a casual DnD player, it was difficult to find a simple,
				web-based and free online tool to get some atmosphere going
				during sessions. So bard.tools was born.
			</p>
			<p>
				As this is a hobby project, there's almost definitely areas for
				improvement. Head to the <a href="/feedback">feedback page</a>{" "}
				if you’ve encountered a bug or have some ideas for the site.
			</p>
			<p>Thanks for visiting!</p>
			<br />
			<br />

			<h4>Planned features:</h4>
			<ul>
				<li>More sounds!</li>
				<li>Campaigns</li>
				<li>Scene cues</li>
				<li>Import/export scenes/clips</li>
				<li>Remote sessions</li>
				<li>Scene one-shot FX</li>
				<li>Dark mode</li>
				<li>Better accessibility</li>
				<li>Improved support for casting</li>
			</ul>
			<br />
			<br />

			<h4>Audio Credits:</h4>
			<p>
				All of the sounds used in bard.tools have either been extended
				(looped) or trimmed to meet a 60 second target length.
				<br />
				Files have also been EQ'd to isolate the key sounds contained
				within them, and/or enhance the quality of the recordings.
				<br />
				Files that were not originally of type .mp3 will have been
				transformed to .mp3.
			</p>
			<ul>
				{SOUND.all.map(({ name, credit }) =>
					credit ? (
						<li>
							{name}:&nbsp;
							<a
								href={credit.url}
								target="_blank"
								rel="noreferrer"
							>
								{credit.name}, by {credit.user}
							</a>
						</li>
					) : null
				)}
			</ul>

			{/* <small>App Version {process.env.REACT_APP_VERSION}</small> */}
		</Page>
	);
};

About.propTypes = {};

export default About;
