import React, { useState } from "react";
import { MODAL_TYPE, PRESET, SCENE_TYPE } from "../../constants";
import useAppState from "../../hooks/useState";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import styled, { css } from "styled-components";
import Options from "../../components/Options";

const Panel = styled.div`
	${({ theme }) => css`
		background-color: rgba(0, 0, 0, 0.2);
		padding: ${theme.space.xs};
	`}
`;

const StyledFooter = styled(Modal.Footer)`
	justify-content: space-between;
`;

const TypePicker = ({ onSelect }) => (
	<Options.Container>
		<Options.Column>
			<Options.Option>
				<Options.Button onClick={() => onSelect(SCENE_TYPE.PRESET)}>
					<Options.Icon>🏕</Options.Icon>
					<Options.Caption>Preset</Options.Caption>
				</Options.Button>
			</Options.Option>
			<Options.Label>
				Choose from a list of pre-made scenes.
			</Options.Label>
		</Options.Column>
		<Options.Column>
			<Options.Option>
				<Options.Button onClick={() => onSelect(SCENE_TYPE.CUSTOM)}>
					<Options.Icon>🎨</Options.Icon>
					<Options.Caption>Custom</Options.Caption>
				</Options.Button>
			</Options.Option>
			<Options.Label>
				Create your own from a bank of sounds and effects.
			</Options.Label>
		</Options.Column>
	</Options.Container>
);

const PresetPicker = ({ onSelect, scenes }) => (
	<Options.Container>
		{Object.entries(PRESET).map(([key, preset], index) => {
			const presetAlreadyUsed = Object.values(scenes).some(
				(scene) => scene.name === preset.name && scene.isPreset
			);

			return (
				<Options.Option key={`key-${index}`}>
					<Options.Button
						$gradient={preset.colours}
						variant={
							presetAlreadyUsed
								? "outline-danger"
								: "outline-primary"
						}
						disabled={presetAlreadyUsed}
						onClick={() => onSelect(PRESET[key])}
					>
						<Options.Icon>{preset.icon}</Options.Icon>
						<Panel>
							<Options.Caption>{preset.name}</Options.Caption>
						</Panel>
					</Options.Button>
				</Options.Option>
			);
		})}
	</Options.Container>
);

const NewSceneModal = () => {
	const { state, actions, selectors } = useAppState();
	const { activeModal } = state;
	const { closeModal, addPresetScene, addCustomScene } = actions;
	const isActive = activeModal === MODAL_TYPE.NEW_SCENE;
	const scenes = selectors.getAllScenes(state);

	const [sceneType, setSceneType] = useState(null);
	const [sceneName, setSceneName] = useState(
		`My Scene ${Object.keys(state.scenes).length + 1}`
	);

	return (
		<Modal
			size="lg"
			animation={false}
			show={isActive}
			onHide={closeModal}
			scrollable={true}
		>
			<Modal.Header closeButton>
				<Modal.Title>New scene</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{sceneType === null ? (
					<TypePicker onSelect={setSceneType} />
				) : sceneType === SCENE_TYPE.PRESET ? (
					<PresetPicker
						scenes={scenes}
						onSelect={(PRESET) => {
							closeModal();
							addPresetScene(PRESET);
						}}
					/>
				) : (
					<Modal.Body>
						<Form.Control
							type="text"
							placeholder="Scene name"
							value={sceneName}
							onChange={(event) =>
								setSceneName(event.target.value)
							}
						/>
					</Modal.Body>
				)}
			</Modal.Body>
			<StyledFooter>
				{sceneType === null && (
					<Button variant="link" onClick={closeModal}>
						Cancel
					</Button>
				)}
				{sceneType !== null && (
					<Button variant="link" onClick={() => setSceneType(null)}>
						&larr; Back
					</Button>
				)}
				{sceneType === SCENE_TYPE.CUSTOM && (
					<Button
						onClick={() => {
							closeModal();
							addCustomScene(sceneName);
						}}
					>
						Add
					</Button>
				)}
			</StyledFooter>
		</Modal>
	);
};

NewSceneModal.propTypes = {};

export default NewSceneModal;
