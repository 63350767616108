import { css } from "styled-components";

const container = ({ theme }) => css`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: ${theme.space.md};
`;

const message = ({ theme }) => css`
	width: 100%;
	white-space: normal;
	text-align: center;
	color: ${theme.colour.text.medium};
`;

const styles = {
	container,
	message,
};

export default styles;
