import React from "react";
import PropTypes from "prop-types";
import { clearLocalStorage } from "../../state/store";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

const Container = styled.div`
	${({ theme }) => theme.pages.error.container}
`;

const Message = styled.pre`
	${({ theme }) => theme.pages.error.message}
`;

const Error = ({ error }) => {
	return (
		<Container>
			<h1>😳</h1>
			<p>Something went wrong.</p>
			<Message>{error.message}</Message>
			<Button
				variant="outline-secondary"
				onClick={() => {
					clearLocalStorage();
					window.location.reload();
				}}
			>
				Force reset app
			</Button>
		</Container>
	);
};

Error.propTypes = {
	error: PropTypes.shape({
		message: PropTypes.string.isRequired,
	}).isRequired,
};

export default Error;
