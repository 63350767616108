import main from "./main";
import controlBar from "./controlBar";
import menu from "./menu";
import scenePreview from "./scenePreview";
import scene from "./scene";
import options from "./options";
import clip from "./clip";
import sidebar from "./sidebar";
import container from "./container";
import visualiser from "./visualiser";

const components = {
	main,
	controlBar,
	menu,
	scenePreview,
	scene,
	options,
	clip,
	sidebar,
	container,
	visualiser,
};

export default components;
