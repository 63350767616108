import * as clips from "../assets/audio";

export const Forest_Day = {
	name: "Forest (Day)",
	icon: "🌲",
	clips: [clips.Birds, { ...clips.Leaves, volume: 1 }],
	colours: ["skyblue", "#2C7744"],
};

export const Forest_Night = {
	name: "Forest (Night)",
	icon: "🌲",
	clips: [
		{ ...clips.Fox_Shrieking, volume: 0.1 },
		{ ...clips.Owls, volume: 0.8 },
		{ ...clips.Wood_Creaking, volume: 0.5 },
		{ ...clips.Leaves, volume: 0.2 },
	],
	colours: ["#283E51", "#355C7D", "#2C7744"],
};

export const Riverbank = {
	name: "Riverbank",
	icon: "🏞",
	clips: [
		{ ...clips.Stream, volume: 0.5 },
		{ ...clips.Birds, volume: 0.7 },
		{ ...clips.Crickets, volume: 0.5 },
	],
	colours: ["#348F50", "#56B4D3"],
};

export const Town_Square = {
	name: "Town Square",
	icon: "🏛",
	clips: [
		clips.Market,
		{ ...clips.Blacksmith, volume: 0.8 },
		{ ...clips.Chicken, volume: 0.5 },
	],
	colours: ["#ffd194", "#CFDEF3"],
};

export const Campsite_Night = {
	name: "Camp",
	icon: "⛺️",
	clips: [
		{ ...clips.Cicadas, volume: 0.5 },
		{ ...clips.Campfire, volume: 1.0 },
		{ ...clips.Owls, volume: 0.1 },
	],
	colours: ["#355C7D", "#355C7D", "#C06C84"],
};

export const Coast = {
	name: "Beach",
	icon: "🏝",
	clips: [
		{ ...clips.Waves, volume: 1.0 },
		{ ...clips.Seagulls, volume: 0.7 },
	],
	colours: ["#2980B9", "#2980B9", "#70e1f5", "#ffd194"],
};

export const Docks = {
	name: "Docks",
	icon: "⚓️",
	clips: [
		clips.Wood_Creaking,
		{ ...clips.Market, volume: 0.5 },
		{ ...clips.Seagulls, volume: 0.6 },
		{ ...clips.Waves, volume: 0.1 },
	],
	colours: ["#2980B9", "#70e1f5", "#CFDEF3"],
};

export const Storm = {
	name: "Storm",
	icon: "⛈",
	clips: [
		{ ...clips.Rain, volume: 0.7 },
		clips.Thunder,
		{ ...clips.Wind, volume: 0.5 },
	],
	colours: ["#373B44", "#4286f4"],
};

export const Shrine = {
	name: "Shrine",
	icon: "⛩",
	clips: [
		{ ...clips.Leaves, volume: 0.3 },
		{ ...clips.Windchimes, volume: 1 },
		{ ...clips.Stream, volume: 0.1 },
	],
	colours: ["#4568DC", "#B06AB3"],
};

export const Sewer = {
	name: "Sewer",
	icon: "🕳",
	clips: [clips.Cave, { ...clips.Stream, volume: 0.1 }],
	colours: ["#093028", "#237A57"],
};
