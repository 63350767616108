import useAppState from "./useState";

function useLogger() {
	const {
		state: {
			options: { debug },
		},
	} = useAppState();

	const trace = (...args) => {
		if (debug) {
			console.log(...args);
		}
	};

	const error = (...args) => {
		console.error(...args);
	};

	return {
		t: trace,
		e: error,
	};
}

export default useLogger;
