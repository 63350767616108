import React from "react";
import useAppState from "../../hooks/useState";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import { PlayFill, PauseFill, List, ArrowDown } from "react-bootstrap-icons";
import { formatConstant } from "../../utils";
import usePlayerContext from "../../hooks/usePlayerContext";
import Spinner from "react-bootstrap/Spinner";

const Container = styled.aside`
	${({ theme }) => theme.components.controlBar.container}
`;

const NowPlaying = styled.div`
	${({ theme }) => theme.components.controlBar.nowPlaying}
`;

const Status = styled.span`
	${({ theme }) => theme.components.controlBar.status}
`;

const SceneName = styled.span`
	${({ theme }) => theme.components.controlBar.sceneName}
`;

const IconWrapper = styled.div`
	${({ theme }) => theme.components.controlBar.iconWrapper}
`;

const StyledButton = styled(Button)`
	${({ theme }) => theme.components.controlBar.button}
`;

const ControlBar = () => {
	const { selectors, state, actions } = useAppState();
	const { toggleSidebar } = actions;
	const { getScene } = selectors;
	const { showSidebar } = state;

	const { play, pause, activeScene, busy, playing } = usePlayerContext();
	const scene = getScene(state, activeScene);

	const togglePlay = () => {
		if (playing) {
			pause();
		} else {
			play(activeScene);
		}
	};

	return (
		<Container>
			<StyledButton variant="link" onClick={toggleSidebar}>
				<IconWrapper $showSidebar={showSidebar}>
					<List />
					<ArrowDown />
				</IconWrapper>
			</StyledButton>
			<NowPlaying $active={activeScene && scene}>
				{activeScene && scene && (
					<>
						<Status>{formatConstant(scene.status)}</Status>
						<SceneName>{scene?.name}</SceneName>
					</>
				)}
			</NowPlaying>
			<StyledButton
				variant="link"
				disabled={busy || !activeScene}
				onClick={togglePlay}
			>
				{busy ? (
					<Spinner animation="border" size="sm" />
				) : playing && activeScene ? (
					<PauseFill />
				) : (
					<PlayFill />
				)}
			</StyledButton>
		</Container>
	);
};

ControlBar.propTypes = {};

export default ControlBar;
