import { css } from "styled-components";

const header = ({ theme }) => css`
	margin-bottom: ${theme.space.sm};
`;

const nameContainer = ({ theme }) => css`
	padding: 0 ${theme.space.lg};
	margin-bottom: ${theme.space.sm};
`;

const name = ({ theme }) => css`
	height: auto;
	font-size: 1.8em;
	border: 0;
	text-align: center;
	font-weight: 500;
	padding-top: 0;
	padding-bottom: 0;

	&:focus {
		box-shadow: none;
	}
`;

const error = ({ theme }) => css`
	display: block;
	text-align: center;
	margin: 0 auto ${theme.space.xs};
	color: ${theme.colour.error};
`;

const details = ({ theme }) => css`
	display: block;
	text-align: center;
`;

const buttonGroup = ({ theme }) => css`
	text-align: center;

	button {
		margin: 0 ${theme.space.xs};
	}
`;

const addButton = ({ theme }) => css`
	height: calc(2 * ${theme.constants.sidebar.mobile});
	width: 100%;
	padding: 0;
	font-size: 1.5em;
`;

const styles = {
	header,
	nameContainer,
	name,
	error,
	details,
	buttonGroup,
	addButton,
};

export default styles;
