const actions = {
	ADD_SCENE: "ADD_SCENE",
	REMOVE_SCENE: "REMOVE_SCENE",
	SET_ACTIVE_SCENE: "SET_ACTIVE_SCENE",
	SET_SCENE_NAME: "SET_SCENE_NAME",
	SET_SCENE_STATUS: "SET_SCENE_STATUS",
	SAVE_SCENE: "SAVE_SCENE",
	ADD_CLIP: "ADD_CLIP",
	REMOVE_CLIP: "REMOVE_CLIP",
	CLEAR_CLIPS: "CLEAR_CLIPS",
	SET_CLIP_VOLUME: "SET_CLIP_VOLUME",
	SET_CLIP_STATUS: "SET_CLIP_STATUS",
	OPEN_MODAL: "OPEN_MODAL",
	CLOSE_MODAL: "CLOSE_MODAL",
	SET_PLAYING: "SET_PLAYING",
	TOGGLE_PLAY: "TOGGLE_PLAYING",
	SET_MASTER_VOLUME: "SET_MASTER_VOLUME",
	TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
	SET_OPTION: "SET_OPTION",
};

export default actions;
