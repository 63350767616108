import styled from "styled-components";
import Button from "react-bootstrap/Button";

const OptionButton = styled(Button).attrs({
	variant: "outline-primary",
})`
	${({ theme }) => theme.components.options.button};
`;

const OptionWrapper = styled.div`
	${({ theme }) => theme.components.options.optionWrapper};
`;

const OptionContainer = styled.div.attrs(({ as }) => ({
	as,
}))`
	${({ theme }) => theme.components.options.optionContainer};
`;

const Option = ({ className, children, ...props }) => (
	<OptionContainer className={className} {...props}>
		<OptionWrapper>{children}</OptionWrapper>
	</OptionContainer>
);

const Label = styled.p`
	${({ theme }) => theme.components.options.label};
`;

const Column = styled.div`
	${({ theme }) =>
		theme.components.options.column({
			theme,
			OptionContainer,
			OptionWrapper,
		})};
`;

const Container = styled.div`
	${({ theme }) => theme.components.options.container};
`;

const Icon = styled.span`
	${({ theme }) => theme.components.options.icon};
`;

const Tags = styled.span`
	${({ theme }) => theme.components.options.tags};
`;

const Caption = styled.p`
	${({ theme }) => theme.components.options.caption};
`;

const Options = {
	Container,
	Column,
	Label,
	Option,
	Button: OptionButton,
	Icon,
	Tags,
	Caption,
};

export default Options;
