import { css } from "styled-components";

const container = ({ theme }) => css`
	height: auto;
	width: 100%;
	z-index: 1;
	position: fixed;
	left: 0;
	bottom: 0;
	display: flex;
	justify-content: space-between;
	background-color: ${theme.colour.background.light};
	border-top: 1px solid ${theme.colour.border.medium};
	box-sizing: border-box;
`;

const nowPlaying = ({ theme, $active }) => css`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: ${theme.space.xs};
	transition: opacity 1s ease;
	opacity: 0;

	${$active &&
	css`
		opacity: 1;
	`}
`;

const status = ({ theme }) => css`
	font-size: 0.7rem;
	opacity: 0.5;
`;

const sceneName = ({ theme }) => css`
	font-size: 0.8rem;
	font-weight: 500;
`;

const button = ({ theme }) => css`
	height: ${theme.constants.sidebar.mobile};
	width: ${theme.constants.sidebar.mobile};
	position: relative;
	overflow: hidden;

	svg {
		height: 100%;
		width: 100%;
	}
`;

const iconWrapper = ({ theme, $showSidebar }) => css`
	height: 200%;
	width: 100%;
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 0;
	left: 0;
	transition: transform 400ms ease;

	${$showSidebar} {
		transform: translateY(50%);
	}

	svg {
		transition: opacity 600ms ease;
		transition-delay: 200ms;
		padding: ${theme.space.sm} ${theme.space.xs};

		&:first-of-type {
			opacity: ${$showSidebar ? "0" : "1"};
		}

		&:last-of-type {
			opacity: ${$showSidebar ? "1" : "0"};
		}
	}
`;

const styles = {
	container,
	nowPlaying,
	status,
	sceneName,
	button,
	iconWrapper,
};

export default styles;
