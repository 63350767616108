import { css } from "styled-components";

const container = ({ theme }) => css`
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${theme.colour.border.medium};
	padding: ${theme.space.sm} ${theme.space.xs};

	&:first-of-type {
		padding-top: ${theme.space.xs};
	}

	${theme.device.tablet} {
		flex: 0 0 50%;
	}
`;

const details = ({ theme }) => css`
	flex: 1 1 50%;
	display: flex;
	flex-direction: column;
`;

const title = ({ theme }) => css`
	white-space: nowrap;
	margin: 0;
`;

const subtitle = ({ theme }) => css`
	margin: 0;
`;

const playButtonWrapper = ({ theme }) => css`
	position: relative;
`;

const playPauseButton = ({ theme, $active }) => css`
	height: ${theme.space.xx};
	width: ${theme.space.xx};
	flex: 0 0 auto;
	padding: 0;
	border-radius: 50%;
	position: relative;
	z-index: 0;
	overflow: hidden;

	transition: all 250ms ease;

	svg {
		transition: all 250ms ease;
		position: relative;
		height: 100%;
		width: 100%;
		z-index: 2;
		transform: scale(0.8);
	}
`;

const buttonContainer = ({ theme }) => css`
	margin: ${theme.space.sm} 0 0;
`;

const styles = {
	container,
	details,
	title,
	subtitle,
	playButtonWrapper,
	playPauseButton,
	buttonContainer,
};

export default styles;
