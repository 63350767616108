import { css } from "styled-components";
import { compose, layout, space } from "styled-system";
import { getGradientString } from "../../utils";

const container = ({ theme }) => css`
	display: flex;
	padding: ${theme.space.xs};
	flex-flow: row wrap;
	margin: -1rem;

	${compose(layout, space)}
`;

const column = ({ theme, OptionContainer, OptionWrapper }) => css`
	width: 50%;
	padding: ${theme.space.xs};
	display: flex;
	flex-direction: column;

	${OptionContainer} {
		width: 100%;
		padding-bottom: 100%;
	}

	${OptionWrapper} {
		width: 100%;
		padding: 0;
	}

	${compose(layout, space)}
`;

const label = ({ theme }) => css`
	display: block;
	text-align: center;
	margin: ${theme.space.sm} auto 0;
	padding: 0 ${theme.space.xs};
`;

const optionContainer = ({ theme }) => css`
	width: 50%;
	position: relative;
	padding-bottom: 50%;
`;

const optionWrapper = ({ theme }) => css`
	position: absolute;
	padding: ${theme.space.xs};
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
`;

const button = ({ theme, $gradient }) => css`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: ${theme.space.xs};
	border-style: dashed;
	padding: ${theme.space.sm};
	border: 1px dashed ${theme.colour.border.medium};
	text-align: center;

	${$gradient &&
	css`
		background: ${getGradientString($gradient)};
		border: 0;

		span,
		p {
			color: #fff;
		}
	`}

	& > *:last-child {
		margin: ${theme.space.sm} auto 0;
	}

	${compose(layout, space)}
`;

const icon = ({ theme }) => css`
	display: block;
	line-height: 1;
	font-size: 4rem;
`;

const tags = ({ theme }) => css`
	display: block;
	line-height: 1;
	font-size: 1.4rem;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
`;

const caption = ({ theme }) => css`
	margin: 0;
`;

const styles = {
	container,
	column,
	label,
	optionContainer,
	optionWrapper,
	button,
	icon,
	tags,
	caption,
};

export default styles;
