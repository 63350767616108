import { useEffect, useRef, useState } from "react";
import { AudioContext } from "standardized-audio-context";
import ease from "../utils/easyEaseModified";
import { SCENE_STATUS, CLIP_STATUS } from "../constants";
import { loadFile } from "../utils";
import useAppState from "./useState";
import usePrevious from "./usePrevious";
import useLogger from "./useLogger";

const promiseAllSequential = async (fn, collection) => {
	for await (const item of collection) {
		fn(item);
	}
};

const Player = () => {
	const logger = useLogger();
	const { state, actions } = useAppState();
	const [activeScene, setActiveScene] = useState(null);
	const [playing, setPlaying] = useState(false);
	const [busy, setIsBusy] = useState(false);

	// directly using useState.setBusy doesn't seem to work :S
	// hence this intermediary func to set busy
	const setBusy = (busy) => {
		setIsBusy(busy);
	};

	const audioContextRef = useRef(null);
	const tracksRef = useRef([]);

	const { scenes, clips, options } = state;
	const { fadeDuration, crossfade } = options;
	const { setSceneStatus, setClipStatus } = actions;

	const clipIds = Object.keys(clips);
	const volumes = Object.entries(clips).map(([id, clip]) => {
		return `${id}:${clip.volume}`;
	});

	const prevVolumes = usePrevious(volumes);

	const getAudioEl = (file) =>
		new Promise((resolve, reject) => {
			const audioEl = new Audio();

			audioEl.addEventListener("canplaythrough", () => {
				resolve(audioEl);
			});

			audioEl.addEventListener("error", (event) => {
				reject(event);
			});

			audioEl.addEventListener("play", () => {
				logger.t("Audio el playing:", file);
			});

			audioEl.addEventListener("pause", () => {
				logger.t("Audio el paused:", file);
			});

			loadFile(file)
				.then((response) => {
					return response.blob();
				})
				.then((blob) => {
					audioEl.src = URL.createObjectURL(blob);
					audioEl.loop = true;
					audioEl.load();
				});
		});

	const fadeInTrack = (id) =>
		new Promise((resolve, reject) => {
			const clip = clips[id];
			const index = getTrackIndex(id);

			logger.t("Fading in...", clip.name);

			tracksRef.current[index].gain.gain.value = 0;
			tracksRef.current[index].el.play();

			ease({
				startValue: 0,
				endValue: clip.volume,
				durationMs: fadeDuration,
				onStep: (value) => {
					tracksRef.current[index].gain.gain.value = value;
				},
				onComplete: () => {
					logger.t("Fade in done", clip.name);
					resolve();
				},
			});
		});

	const fadeOutTrack = (id) =>
		new Promise((resolve) => {
			const index = getTrackIndex(id);
			const track = tracksRef.current[index];

			logger.t("Fading out...", track.name);

			ease({
				startValue: track.gain.gain.value,
				endValue: 0,
				durationMs: fadeDuration,
				onStep: (value) => {
					tracksRef.current[index].gain.gain.value = value;
				},
				onComplete: () => {
					logger.t("Fade out done", track.name);
					tracksRef.current[index].el.pause();
					resolve();
				},
			});
		});

	const getTrackIndex = (id) => {
		return tracksRef.current.findIndex((track) => track.id === id);
	};

	const getTrackAudio = async (id, clip) => {
		logger.t("Creating audio for", clip.name);

		const index = getTrackIndex(id);
		const { file, volume } = clip;

		setClipStatus(id, CLIP_STATUS.LOADING);

		const el = await getAudioEl(file);
		const source = audioContextRef.current.createMediaElementSource(el);
		const gainNode = audioContextRef.current.createGain();

		gainNode.gain.value = volume;

		gainNode.connect(audioContextRef.current.destination);
		source.connect(gainNode);

		tracksRef.current[index].el = el;
		tracksRef.current[index].gain = gainNode;
		tracksRef.current[index].source = source;

		setClipStatus(id, CLIP_STATUS.READY);

		return tracksRef.current[index];
	};

	const removeTrack = (id) =>
		new Promise((resolve, reject) => {
			const index = getTrackIndex(id);
			logger.t("Removing track", tracksRef.current[index].name);

			// Remove from array
			tracksRef.current.splice(index, 1);

			resolve();
		});

	const playTrack = async (id) => {
		setClipStatus(id, CLIP_STATUS.FADE_IN);

		await fadeInTrack(id);

		setClipStatus(id, CLIP_STATUS.PLAYING);

		return;
	};

	const pauseTrack = (id) =>
		new Promise((resolve, reject) => {
			const index = getTrackIndex(id);

			if (tracksRef.current[index].el) {
				if (tracksRef.current[index].el.paused) {
					resolve();
				} else {
					setClipStatus(id, CLIP_STATUS.FADE_OUT);
					fadeOutTrack(id).then(() => {
						setClipStatus(id, CLIP_STATUS.READY);
						resolve();
					});
				}
			} else {
				setClipStatus(id, CLIP_STATUS.READY);
				resolve();
			}
		});

	const playScene = async (id) => {
		// Pause current scene and preload queued scene
		if (playing) {
			await Promise.all([
				pauseScene(activeScene, true),
				Promise.all(
					scenes[id].clips.map((id) => {
						const clip = clips[id];
						return getTrackAudio(id, clip);
					})
				).then(() => {
					setSceneStatus(id, SCENE_STATUS.QUEUED);
				}),
			]);
		} else {
			setSceneStatus(id, SCENE_STATUS.LOADING);
		}

		audioContextRef.current.resume();

		setBusy(true);
		setPlaying(true);
		setActiveScene(id);

		const scene = scenes[id];
		logger.t("Playing scene", scene.name);

		// Load all tracks
		await Promise.all(
			scene.clips.map((id) => {
				const index = getTrackIndex(id);

				if (tracksRef.current[index].el) {
					return Promise.resolve();
				} else {
					const clip = clips[id];
					return getTrackAudio(id, clip);
				}
			})
		);

		setSceneStatus(id, SCENE_STATUS.FADE_IN);

		// Then play together
		await Promise.all(
			scene.clips.map((id) => {
				return playTrack(id);
			})
		);

		setBusy(false);
		setSceneStatus(id, SCENE_STATUS.PLAYING);
		logger.t("Scene playing", scene.name);
	};

	const pauseScene = async (id = activeScene, isCrossFading = false) => {
		const scene = scenes[id];
		logger.t("Pausing scene", scene.name);

		const fadeOutTracks = () =>
			Promise.all(
				scene.clips.map((id) => {
					const index = getTrackIndex(id);

					if (tracksRef.current[index].el) {
						return pauseTrack(id);
					} else {
						return Promise.resolve();
					}
				})
			);

		setSceneStatus(id, SCENE_STATUS.FADE_OUT);

		if (isCrossFading && crossfade) {
			fadeOutTracks().then(() => {
				setSceneStatus(id, SCENE_STATUS.READY);
				logger.t("Scene paused", scene.name);
			});

			return Promise.resolve();
		} else {
			setBusy(true);

			await fadeOutTracks();

			logger.t("Scene paused", scene.name);

			setSceneStatus(id, SCENE_STATUS.READY);
			setPlaying(false);
			setBusy(false);

			// Pause audioContext (saves battery)
			audioContextRef.current.suspend();

			return Promise.resolve();
		}
	};

	const diffArrays = (arr1 = [], arr2 = []) => {
		const difference = arr1
			.filter((x) => !arr2.includes(x))
			.concat(arr2.filter((x) => !arr1.includes(x)));

		return difference;
	};

	const provisionTrack = (id) => {
		const clip = clips[id];
		const { file, name } = clip;

		logger.t("Provisioning track for", name);

		tracksRef.current.push({
			id,
			file,
			name,
		});
	};

	const handleClipsChange = async (clips, tracks) => {
		const trackIds = tracks.map((track) => track.id);
		const clipIds = Object.keys(clips);

		const ids = diffArrays(trackIds, clipIds);

		if (clipIds.length > tracks.length) {
			// Provision tracks
			ids.forEach(provisionTrack);

			return await Promise.all(
				ids.map((id) => {
					const isActiveClip =
						scenes[activeScene]?.clips.includes(id);

					// Play track
					if (isActiveClip) {
						const clip = clips[id];

						if (playing) {
							// Load clip, create audio and play
							return getTrackAudio(id, clip).then(() => {
								return playTrack(id);
							});
						} else {
							// Load audio, but don't play
							setClipStatus(id, CLIP_STATUS.LOADING);

							return loadFile(clip.file).then(() => {
								setClipStatus(id, CLIP_STATUS.READY);
							});
						}
					} else {
						return Promise.resolve();
					}
				})
			);
		} else if (tracks.length > clipIds.length) {
			// If clips are now empty, clear statuses
			if (clipIds.length === 0) {
				setPlaying(false);
				setActiveScene(null);
			}

			// Pause deleted tracks
			return Promise.all(ids.map((id) => pauseTrack(id)))
				.then(() => {
					// Then remove sequentially
					return promiseAllSequential(removeTrack, ids);
				})
				.then(() => {
					// Then reset playing/activeScene if the active scene was deleted
					if (!scenes[activeScene]) {
						logger.t(
							"Active scene not found! Resetting player state"
						);
						setActiveScene(null);
						setPlaying(false);
					}
				});
		} else {
			return Promise.resolve();
		}
	};

	useEffect(() => {
		// If tracks have been provisioned/created
		if (tracksRef.current.length) {
			// Compare old volumes and new volumes
			const volumeDiff = diffArrays(volumes, prevVolumes);

			// If diff has contains an old and new volume for a single id
			if (volumeDiff.length === 2) {
				const ids = volumeDiff.map((x) => x.split(":")[0]);

				if (ids[0] === ids[1]) {
					const id = ids[0];
					const clip = clips[id];
					const index = getTrackIndex(id);

					logger.t("Setting volume", clip.name, clip.volume);

					// update volume of track
					if (tracksRef.current[index].gain) {
						tracksRef.current[index].gain.gain.value = clip.volume;
					}
				}
			}
		}
	}, [volumes]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		logger.t("Clips.length changed", clipIds);
		setBusy(true);
		handleClipsChange(clips, tracksRef.current).then(() => {
			logger.t("Tracks updated", tracksRef.current);
			setBusy(false);
		});
	}, [clipIds.length]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		audioContextRef.current = new AudioContext();

		return () => {
			audioContextRef.current.close();
		};
	}, []);

	return {
		activeScene,
		busy,
		playing,
		play: playScene,
		pause: (id) => pauseScene(id),
	};
};

export default Player;
