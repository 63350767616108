import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useAppState from "../../hooks/useState";
import Clip from "../Clip";
import {
	MODAL_TYPE,
	ROUTE,
	SCENE_STATUS,
	VALID_SCENE_NAME,
} from "../../constants";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import DeleteButton from "../DeleteButton";
import { BackButton } from "../Page";
import { formatConstant, getSceneIsBusy } from "../../utils";
import usePlayerContext from "../../hooks/usePlayerContext";

const Header = styled.div`
	${({ theme }) => theme.components.scene.header}
`;

const NameContainer = styled.div`
	${({ theme }) => theme.components.scene.nameContainer}
`;

const Name = styled(Form.Control)`
	${({ theme }) => theme.components.scene.name}
`;

const Error = styled.small`
	${({ theme }) => theme.components.scene.error}
`;

const Details = styled.small`
	${({ theme }) => theme.components.scene.details}
`;

const ButtonGroupWrapper = styled.div`
	${({ theme }) => theme.components.scene.buttonGroup}
`;

const ClipsContainer = styled.div`
	${({ theme }) => theme.components.container}
`;

const AddButton = styled(Button)`
	${({ theme }) => theme.components.scene.addButton}
`;

const Scene = ({ id, name, clips, isPreset, status }) => {
	const { push, replace } = useHistory();
	const { actions } = useAppState();
	const [sceneName, setSceneName] = useState(name);
	const nameElement = useRef();

	const { play, pause, busy } = usePlayerContext();
	const sceneIsBusy = getSceneIsBusy(status);
	const formattedStatus = formatConstant(status);

	let friendlyStatus = formattedStatus;

	if (formattedStatus === "Ready") {
		friendlyStatus = "Play";
	}

	if (formattedStatus === "Playing") {
		friendlyStatus = "Pause";
	}

	useEffect(() => {
		if (clips.length === 0) {
			setSceneStatus(id, SCENE_STATUS.READY);
		}
	}, [clips.length]); // eslint-disable-line react-hooks/exhaustive-deps

	const {
		removeClipFromScene,
		openModal,
		removeScene,
		setSceneStatus,
		saveScene,
	} = actions;

	const nameIsValid = new RegExp(VALID_SCENE_NAME).test(sceneName);
	const nameModified = sceneName !== name;
	const nameHasError = nameModified && !nameIsValid;

	return (
		<div>
			<BackButton />
			<Header>
				<NameContainer>
					<Name
						isInvalid={nameHasError}
						value={sceneName}
						onChange={(event) => {
							setSceneName(event.target.value);
						}}
						onKeyDown={(event) => {
							if (event.code === "Enter") {
								if (nameModified && nameIsValid) {
									saveScene(id, sceneName);
									replace(ROUTE.home.path);
								} else {
									nameElement.current.blur();
								}
							}
						}}
						ref={nameElement}
					/>
					{nameHasError && <Error>Invalid scene name.</Error>}
					<Details>
						{isPreset ? "Preset" : "Custom"} &bull;{" "}
						{clips.length === 0
							? "No clips"
							: clips.length === 1
							? "1 clip"
							: `${clips.length} clips`}
					</Details>
				</NameContainer>
				<ButtonGroupWrapper>
					<DeleteButton
						disabled={sceneIsBusy || busy}
						size="sm"
						onDelete={() => {
							push(ROUTE.home.path);
							removeScene(id);
						}}
					/>
					<Button
						size="sm"
						disabled={!nameIsValid || sceneIsBusy || busy}
						onClick={() => {
							if (nameModified && nameIsValid) {
								saveScene(id, sceneName);
								replace(ROUTE.home.path);
							} else {
								nameElement.current.select();
							}
						}}
						variant={
							nameModified ? "secondary" : "outline-secondary"
						}
					>
						{nameModified ? "Save name" : "Rename"}
					</Button>
					<Button
						disabled={busy || clips.length === 0}
						size="sm"
						variant={
							status === SCENE_STATUS.READY
								? "success"
								: "outline-success"
						}
						onClick={() => {
							if (status === SCENE_STATUS.PLAYING) {
								pause(id);
							}
							if (status === SCENE_STATUS.READY) {
								play(id);
							}
						}}
					>
						{friendlyStatus}
					</Button>
				</ButtonGroupWrapper>
			</Header>
			<ClipsContainer>
				{Object.values(clips).map((clip, index) => (
					<Clip
						key={`${clip.name}-${index}`}
						sceneId={id}
						onDelete={() => removeClipFromScene(id, clip.id)}
						{...clip}
					/>
				))}
				<AddButton
					disabled={busy}
					variant="link"
					onClick={() => openModal(MODAL_TYPE.ADD_CLIP)}
				>
					+ Add clip
				</AddButton>
			</ClipsContainer>
		</div>
	);
};

Scene.propTypes = {};

export default Scene;
