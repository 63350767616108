import React from "react";
import PropTypes from "prop-types";
import useAppState from "../../hooks/useState";
import styled, { css } from "styled-components";
import DeleteButton from "../DeleteButton";
import { X } from "react-bootstrap-icons";
import { CLIP_STATUS } from "../../constants";
import usePlayerContext from "../../hooks/usePlayerContext";
import Spinner from "react-bootstrap/Spinner";
import Visualiser from "../Visualiser";

const Container = styled.div`
	${({ theme }) => theme.components.clip.container}
`;

const Icon = styled.span`
	${({ theme }) => theme.components.clip.icon}
`;

const Title = styled.h5`
	${({ theme }) => theme.components.clip.title}
`;

const Control = styled.div`
	${({ theme }) => theme.components.clip.control}
`;

const Input = styled.input.attrs({
	type: "range",
	min: "0",
	max: "1.0",
	step: "0.1",
})`
	${({ theme }) => theme.components.clip.input}
`;

const StatusIcon = styled.div`
	${({ theme }) => theme.components.clip.status}
`;

const ControlLabel = styled.small`
	${({ theme }) => theme.components.clip.label}
`;

const StyledDeleteButton = styled(DeleteButton)`
	${({ theme }) =>
		css`
			${theme.components.clip.deleteButton}

			padding: ${theme.space.xs};

			svg {
				display: block;
			}
		`}
`;

const Clip = ({ sceneId, id, name, icon, volume, onDelete, status }) => {
	const { actions } = useAppState();
	const { busy } = usePlayerContext();

	const { setClipVolume } = actions;

	const showVisualiser =
		status === CLIP_STATUS.FADE_IN || status === CLIP_STATUS.PLAYING;

	const clipBusy =
		status === CLIP_STATUS.INIT ||
		status === CLIP_STATUS.LOADING ||
		status === CLIP_STATUS.QUEUED ||
		status === CLIP_STATUS.FADE_IN ||
		status === CLIP_STATUS.FADE_OUT;

	return (
		<Container>
			<Icon $volume={volume}>{icon}</Icon>
			<Title>{name}</Title>
			<StatusIcon>
				{status === CLIP_STATUS.LOADING && (
					<Spinner
						as="span"
						size="sm"
						animation="border"
						variant="secondary"
					/>
				)}
				<Visualiser show={showVisualiser} scale={volume} />
			</StatusIcon>
			<Control>
				<ControlLabel>
					<span>Volume</span>
					<span>{volume * 100}%</span>
				</ControlLabel>
				<Input
					disabled={clipBusy || busy}
					value={volume}
					onChange={(event) => {
						setClipVolume(sceneId, id, event.target.value);
					}}
				/>
			</Control>
			<StyledDeleteButton
				disabled={clipBusy || busy}
				text={<X />}
				confirm={<X />}
				onDelete={onDelete}
			/>
		</Container>
	);
};

Clip.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	file: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default Clip;
