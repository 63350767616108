import React, { useState } from "react";
import DeleteButton from "../../components/DeleteButton";
import Page from "../../components/Page";
import { ROUTE } from "../../constants";
import usePlayerContext from "../../hooks/usePlayerContext";
import useAppState from "../../hooks/useState";
import { clearLocalStorage } from "../../state/store";

const Options = () => {
	const { busy } = usePlayerContext();
	const { actions, state } = useAppState();
	const { setOption } = actions;

	const [options, setOptions] = useState(state.options);

	const updateOption = (key, value) => {
		if (!busy) {
			setOptions({
				...options,
				[key]: value,
			});

			setOption(key, value);
		}
	};

	return (
		<Page>
			<h1>Options</h1>

			<section>
				<h2>Cross fade scenes</h2>
				<p>
					If a scene is already playing when playing a new one, the
					two scenes will fade in/out at the same time. If unchecked,
					the current scene will pause first before queuing the next
					scene to play.
				</p>
				<input
					type="checkbox"
					checked={options.crossfade}
					disabled={busy}
					onChange={(event) => {
						updateOption("crossfade", event.target.checked);
					}}
				/>
			</section>

			<section>
				<h2>Fade duration</h2>
				<p>
					The length of time it takes scenes to fade in or out.
					Quicker fade times might make transitions between scenes
					more noticable. Use in conjunction with crossfade to make
					your scene transitions less noticable.
				</p>
				<input
					type="number"
					min="0"
					max="60000"
					step="500"
					disabled={busy}
					value={options.fadeDuration}
					onChange={(event) => {
						updateOption("fadeDuration", +event.target.value);
					}}
				/>
				ms ({options.fadeDuration / 1000}s)
			</section>

			{/* <section>
				<h2>Debug mode</h2>
			</section> */}

			<section>
				<h2>Force reset app</h2>
				<p>
					If the app isn't behaving correctly, resetting might help.{" "}
					<strong>
						Warning: this will clear these options and all of your
						scenes and clips.
					</strong>
					.
				</p>
				<DeleteButton
					text="Reset App"
					confirm="Are you sure?"
					disabled={busy}
					onDelete={() => {
						clearLocalStorage();
						window.location.assign(ROUTE.home.path);
					}}
				/>
			</section>
		</Page>
	);
};

Options.propTypes = {};

export default Options;
