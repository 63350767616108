import { SCENE_STATUS } from "../constants";
import types from "./types";

// Scenes
export const addCustomScene = (name) => ({
	type: types.ADD_SCENE,
	name,
	isPreset: false,
});
export const addPresetScene = ({ name, clips }) => ({
	type: types.ADD_SCENE,
	name,
	clips,
	isPreset: true,
});
export const removeScene = (id) => ({
	type: types.REMOVE_SCENE,
	id,
});
export const saveScene = (id, name) => ({
	type: types.SAVE_SCENE,
	id,
	name,
});
export const setSceneStatus = (id, status = SCENE_STATUS.READY) => ({
	type: types.SET_SCENE_STATUS,
	id,
	status,
});

// Clips
export const addClipToScene = (sceneId, sound) => ({
	type: types.ADD_CLIP,
	sceneId,
	sound,
});
export const removeClipFromScene = (sceneId, clipId) => ({
	type: types.REMOVE_CLIP,
	sceneId,
	clipId,
});
export const setClipVolume = (sceneId, clipId, volume) => ({
	type: types.SET_CLIP_VOLUME,
	sceneId,
	clipId,
	volume,
});
export const setClipStatus = (id, status) => ({
	type: types.SET_CLIP_STATUS,
	id,
	status,
});

// Modals
export const openModal = (modal) => ({ type: types.OPEN_MODAL, modal });
export const closeModal = () => ({ type: types.CLOSE_MODAL });

// Control Bar
export const toggleSidebar = () => ({
	type: types.TOGGLE_SIDEBAR,
});

export const setMasterVolume = (volume) => ({
	type: types.SET_MASTER_VOLUME,
	volume,
});

// Options
export const setOption = (key, value) => ({
	type: types.SET_OPTION,
	key,
	value,
});
