const space = [0, "0.4rem", "0.8rem", "1.2rem", "2rem", "3rem", "6rem"];

space.xs = space[1];
space.sm = space[2];
space.md = space[3];
space.lg = space[4];
space.xl = space[5];
space.xx = space[6];

export default space;
