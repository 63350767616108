import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ThemeProvider } from "styled-components";

import theme, { GlobalStyle } from "./theme";
import Main from "./components/Main";
import State from "./state";
import Error from "./pages/Error";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<BrowserRouter>
				<State>
					<ErrorBoundary FallbackComponent={Error}>
						<Main />
					</ErrorBoundary>
				</State>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
