import { css } from "styled-components";

const visualiser = ({
	theme,
	$opacity = 1,
	$show,
	$scale = 1,
	$transitionDuration = 2000,
}) => css`
	display: inline;
	vertical-align: baseline;
	height: 1em;
	width: 1em;
	margin-left: ${theme.space.xs};
	transition: transform ${$transitionDuration + 1000}ms ease,
		oapcity 250ms ease;
	transform: scaleY(0);
	transform-origin: 0 100%;

	${$show &&
	css`
		transform: scaleY(${$scale});
		opacity: ${$opacity};
	`}
`;

export default visualiser;
