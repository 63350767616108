import React from "react";
import Button from "react-bootstrap/Button";
import ScenePreview from "../ScenePreview/ScenePreview";
import useAppState from "../../hooks/useState";
import { MODAL_TYPE } from "../../constants";
import styled from "styled-components";
import usePlayerContext from "../../hooks/usePlayerContext";

const Container = styled.div`
	${({ theme }) => theme.components.container}
	${({ theme }) => theme.components.menu.container}
`;

const ButtonContainer = styled.div`
	${({ theme }) => theme.components.menu.buttonContainer}
`;

const AddButton = styled(Button)`
	${({ theme }) => theme.components.menu.button}
`;

const Fallback = styled.div`
	${({ theme }) => theme.components.menu.fallback}
`;

const Icon = styled.h1`
	${({ theme }) => theme.components.menu.icon}
`;

const GetStartedButton = styled(Button)`
	display: flex;
`;

const Menu = () => {
	const { state, actions, selectors } = useAppState();
	const { busy } = usePlayerContext();
	const { openModal } = actions;

	const scenes = selectors.getAllScenes(state);

	return scenes.length ? (
		<Container>
			{scenes.map((scene) => (
				<ScenePreview key={scene.id} {...scene} />
			))}
			<ButtonContainer>
				<AddButton
					variant="link"
					onClick={() => openModal(MODAL_TYPE.NEW_SCENE)}
				>
					+ Add scene
				</AddButton>
			</ButtonContainer>
		</Container>
	) : (
		<Fallback>
			<Icon>🎭</Icon>
			<p>No scenes yet.</p>
			<GetStartedButton
				disabled={busy}
				variant="success"
				size="lg"
				onClick={() => openModal(MODAL_TYPE.NEW_SCENE)}
			>
				Let's go!
			</GetStartedButton>
		</Fallback>
	);
};

Menu.propTypes = {};

export default Menu;
