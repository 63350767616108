import { css } from "styled-components";

const container = ({ theme }) => css`
	padding: ${theme.space.sm} ${theme.space.xs};
	border-bottom: 1px solid ${theme.colour.border.medium};
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const icon = ({ theme }) => css`
	font-size: 1.5em;
	margin-right: ${theme.space.xs};
`;

const title = ({ theme }) => css`
	font-size: 1rem;
	flex: 1 1 auto;
	margin: 0;
`;

const control = ({ theme }) => css`
	flex: 0 0 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 ${theme.space.sm};
`;

const input = ({ theme }) => css`
	width: 100%;
`;

const label = ({ theme }) => css`
	width: 100%;
	margin-bottom: ${theme.space.xs};
	display: flex;
	justify-content: space-between;
`;

const status = ({ theme }) => css`
	height: 1rem;
	width: 1rem;
	position: relative;

	svg,
	span {
		margin: 0;
		position: absolute;
		max-height: 100%;
		width: 100%;
		top: 0;
		left: 0;
	}
`;

const deleteButton = ({ theme }) => css``;

const styles = {
	container,
	icon,
	title,
	control,
	input,
	label,
	deleteButton,
	status,
};

export default styles;
