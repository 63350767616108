export const getClip = (state, id) => {
	return state.clips[id];
};

export const getClips = (state, ids) => {
	return ids.map((id) => getClip(state, id));
};

export const getScene = (state, id) => {
	if (!id) {
		return null;
	}

	const scene = state.scenes[id];

	if (!scene) {
		return null;
	}

	const clipsWithIds = getClips(state, scene.clips).map((clip, index) => ({
		id: scene.clips[index],
		...clip,
	}));

	const sceneWithClips = {
		id,
		...scene,
		clips: clipsWithIds,
	};

	return sceneWithClips;
};

export const getSceneStatus = (state, id) => {
	return state.scenes[id].status;
};

export const getSceneByPath = (state, path) => {
	if (!path) {
		return null;
	}

	if (Object.keys(state.scenes).length === 0) {
		return null;
	}

	const result = Object.entries(state.scenes).find(
		([, scene]) => scene.path === path
	);

	if (!result) {
		return null;
	}

	return getScene(state, result[0]);
};

export const getScenes = (state, ids) => {
	return ids.map((id) => getScene(state, id));
};

export const getAllScenes = (state) => {
	return Object.keys(state.scenes).map((id) => getScene(state, id));
};

export const getAllClips = (state) => {
	return Object.entries(state.clips).map(([key, value]) => ({
		id: key,
		...value,
	}));
};
