import React from "react";
import Page from "../../components/Page";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Feedback = () => (
	<Page>
		<h1>Get in touch!</h1>
		<br />
		<p>
			Have a suggestion? Or something not quite working right? Let us know
			using the form below.
		</p>
		<Form name="feedback" method="post">
			<input type="hidden" name="form-name" value="feedback" />
			<Form.Group>
				<Form.Label>Reason for contact</Form.Label>
				<Form.Control as="select" name="reason">
					<option>Idea or suggestion</option>
					<option>Bug report</option>
					<option>Other</option>
				</Form.Control>
			</Form.Group>
			<Form.Group>
				<Form.Label>Details</Form.Label>
				<Form.Control as="textarea" name="details" required rows={3} />
			</Form.Group>
			<Button variant="primary" type="submit">
				Submit
			</Button>
		</Form>
	</Page>
);

Feedback.propTypes = {};

export default Feedback;
