export const day = {
	symbol: "☀️",
	alt: "Day",
	keywords: ["day", "sunny", "daytime"],
};

export const night = {
	symbol: "🌙",
	alt: "Night",
	keywords: ["night", "night time", "moon", "dark"],
};

export const forest = {
	symbol: "🌲",
	alt: "Forest",
	keywords: ["forest", "woods", "woodland", "trees"],
};

export const river = {
	symbol: "🏞",
	alt: "River",
	keywords: ["river", "stream", "water"],
};

export const town = {
	symbol: "🏛",
	alt: "Town",
	keywords: ["town", "city", "buildings"],
};

export const people = {
	symbol: "🗣",
	alt: "People",
	keywords: ["people", "talk", "talking", "voice"],
};

export const fire = {
	symbol: "🔥",
	alt: "Fire",
	keywords: ["fire", "campfire"],
};

export const camp = {
	symbol: "⛺️",
	alt: "Camp",
	keywords: ["camp", "watch", "tent"],
};

export const ocean = {
	symbol: "🌊",
	alt: "Ocean",
	keywords: ["ocean", "sea", "water", "coast", "waves"],
};

export const birds = {
	symbol: "🦜",
	alt: "Birds (Forest)",
	keywords: ["birds", "forest", "day"],
};

export const seabirds = {
	symbol: "🐦",
	alt: "Birds (sea)",
	keywords: ["birds", "seabirds", "gulls", "seagulls", "ocean"],
};

export const rain = {
	symbol: "☔️",
	alt: "Rain",
	keywords: ["rain", "wet", "pour", "forest"],
};

export const tools = {
	symbol: "🔨",
	alt: "Tools",
	keywords: ["tools", "workshop", "smith", "forge", "anvil"],
};

export const insects = {
	symbol: "🦗",
	alt: "Insects",
	keywords: ["insects", "bugs", "chirps"],
};

export const chickens = {
	symbol: "🐔",
	alt: "Chicken",
	keywords: ["chicken", "cockerel", "hen", "farm", "animal"],
};

export const cows = {
	symbol: "🐮",
	alt: "Cow",
	keywords: ["cow", "moo", "farm", "animal"],
};

export const foxes = {
	symbol: "🦊",
	alt: "Fox",
	keywords: ["fox", "shriek", "night", "spooky", "creepy"],
};

export const leaves = {
	symbol: "🍃",
	alt: "Leaves",
	keywords: ["leaves", "leaf", "wind", "breeze"],
};

export const wind = {
	symbol: "💨",
	alt: "Wind",
	keywords: ["wind", "gust", "breeze"],
};

export const creepy = {
	symbol: "🙀",
	alt: "Creepy",
	keywords: ["creepy", "scary", "spooky", "fear"],
};
