import React, { useState } from "react";
import {
	MODAL_TYPE,
	ROUTE,
	SEARCH_TYPE,
	CATEGORIES,
	SOUND,
} from "../../constants";
import useAppState from "../../hooks/useState";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useRouteMatch } from "react-router-dom";
import Options from "../../components/Options";
import styled from "styled-components";
import { formatConstant } from "../../utils";

const StyledFooter = styled(Modal.Footer)`
	justify-content: space-between;
`;

const icons = {
	[SEARCH_TYPE.ALL]: "📒",
	[SEARCH_TYPE.CATEGORY]: "🗂",
	[SEARCH_TYPE.TAG]: "🏷",
	[SEARCH_TYPE.QUERY]: "🔍",
};

const MainMenu = ({ onSelect }) =>
	Object.values(SEARCH_TYPE).map((searchType) => (
		<Options.Option key={searchType}>
			<Options.Button onClick={() => onSelect(searchType)}>
				<Options.Icon>{icons[searchType]}</Options.Icon>
				<Options.Caption>{formatConstant(searchType)}</Options.Caption>
			</Options.Button>
		</Options.Option>
	));

const Categories = ({ options, onSelect }) =>
	Object.values(options).map((option) => (
		<Options.Option key={option.name}>
			<Options.Button
				onClick={() => {
					onSelect(option);
				}}
			>
				<Options.Icon>{option.icon}</Options.Icon>
				<Options.Caption>{option.name}</Options.Caption>
			</Options.Button>
		</Options.Option>
	));

const Results = ({ sceneId, results, clipNames, addClipToScene, closeModal }) =>
	results.map((sound) => (
		<Options.Option key={sound.name}>
			<Options.Button
				disabled={clipNames.includes(sound.name)}
				onClick={() => {
					addClipToScene(sceneId, sound);
					closeModal();
				}}
			>
				<Options.Icon>{sound.icon}</Options.Icon>
				<Options.Caption>{sound.name}</Options.Caption>
			</Options.Button>
		</Options.Option>
	));

const AddClipModal = () => {
	const { state, actions, selectors } = useAppState();
	const { activeModal } = state;
	const { closeModal, addClipToScene } = actions;
	const isActive = activeModal === MODAL_TYPE.ADD_CLIP;
	const {
		params: { path },
	} = useRouteMatch(ROUTE.scene.path);

	const { id, clips } = selectors.getSceneByPath(state, path);
	const clipNames = clips.map((clip) => clip.name);

	const [searchMethod, setSearchMethod] = useState(null);
	const [results, setResults] = useState(null);

	return (
		<Modal
			size="lg"
			animation={false}
			show={isActive}
			onHide={closeModal}
			scrollable={true}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{searchMethod ? formatConstant(searchMethod) : "Add clip"}
					{searchMethod === SEARCH_TYPE.CATEGORY && results && (
						<> &rarr; {results.name}</>
					)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Options.Container>
					{results ? (
						<Results
							sceneId={id}
							results={results.clips}
							clipNames={clipNames}
							addClipToScene={addClipToScene}
							closeModal={closeModal}
						/>
					) : searchMethod === null ? (
						<MainMenu
							onSelect={(searchMethod) => {
								setSearchMethod(searchMethod);
								if (searchMethod === SEARCH_TYPE.ALL) {
									setResults({
										name: "all",
										clips: [...SOUND.all],
									});
								}
							}}
						/>
					) : searchMethod === SEARCH_TYPE.CATEGORY ? (
						<Categories
							options={CATEGORIES}
							onSelect={(category) => setResults(category)}
						/>
					) : null}
				</Options.Container>
			</Modal.Body>
			<StyledFooter>
				<Button
					variant="link"
					onClick={() => {
						switch (searchMethod) {
							case SEARCH_TYPE.ALL:
								setResults(null);
								setSearchMethod(null);
								break;

							case null:
								closeModal();
								break;

							default:
								if (results) {
									setResults(null);
								} else {
									setSearchMethod(null);
								}
						}
					}}
				>
					{searchMethod ? <>&larr; Back</> : "Cancel"}
				</Button>
			</StyledFooter>
		</Modal>
	);
};

AddClipModal.propTypes = {};

export default AddClipModal;
