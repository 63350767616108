import { CACHE, SCENE_STATUS } from "../constants";

export const sanitiseSceneName = (name) => {
	const dashed = name.replace(/\s+/g, "-");
	return encodeURIComponent(dashed);
};

export const formatConstant = (string) => {
	if (!string) {
		return null;
	}

	const str = string.split("_").pop().replace("-", " ");
	const cap = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	return cap;
};

export const getGradientString = (colours) => {
	return `linear-gradient(to bottom, ${colours.join(", ")})`;
};

export const _getCache = async () => {
	const caches = window.caches;
	const cache = await caches.open(CACHE.AUDIO);

	return cache;
};

export const loadFile = (file) => {
	return _getCache().then((cache) => _fetchAndCache(file, cache));
};

export const loadFiles = (clips) =>
	_getCache().then((cache) =>
		Promise.all(clips.map((clip) => _fetchAndCache(clip.file, cache)))
	);

export const _fetchAndCache = (url, cache) => {
	return cache.match(url).then((cacheResponse) => {
		// Let's return cached response if video is already in the cache.
		if (cacheResponse) {
			return cacheResponse;
		}
		// Otherwise, fetch the video from the network.
		return fetch(url).then((networkResponse) => {
			// Add the response to the cache and return network response in parallel.
			cache.put(url, networkResponse.clone());
			return networkResponse;
		});
	});
};

export const getSceneIsBusy = (status) => {
	return (
		status === SCENE_STATUS.INIT ||
		status === SCENE_STATUS.LOADING ||
		status === SCENE_STATUS.QUEUED ||
		status === SCENE_STATUS.FADE_IN ||
		status === SCENE_STATUS.FADE_OUT
	);
};
