export * as PRESET from "./presets";
export * as SOUND from "../assets/audio";
export * as CATEGORIES from "./categories";
export * as TAGS from "./tags";

export const CACHE = {
	AUDIO: "AUDIO_CACHE",
};

export const SCENE_TYPE = {
	PRESET: "SCENE_TYPE_PRESET",
	CUSTOM: "SCENE_TYPE_CUSTOM",
};

export const MODAL_TYPE = {
	NEW_SCENE: "MODAL_TYPE_ADD_SCENE",
	ADD_CLIP: "MODAL_TYPE_ADD_CLIP",
};

export const SEARCH_TYPE = {
	ALL: "SEARCH_TYPE_ALL",
	CATEGORY: "SEARCH_TYPE_CATEGORY",
	// QUERY: "SEARCH_TYPE_QUERY",
	// TAG: "SEARCH_TYPE_TAG",
};

export const ROUTE = {
	home: {
		name: "My Scenes",
		path: "/",
	},
	about: {
		name: "About",
		path: "/about",
	},
	options: {
		name: "Options",
		path: "/options",
	},
	scene: {
		name: "Scene",
		path: "/scene/:path",
	},
	feedback: {
		name: "Feedback",
		path: "/feedback",
	},
	newScene: (name) => `/scene/${name}`,
};

export const SCENE_STATUS = {
	INIT: "SCENE_STATUS_INIT",
	READY: "SCENE_STATUS_READY",
	QUEUED: "SCENE_STATUS_QUEUED",
	LOADING: "SCENE_STATUS_LOADING",
	FADE_IN: "SCENE_STATUS_FADING-IN",
	FADE_OUT: "SCENE_STATUS_FADING-OUT",
	PLAYING: "SCENE_STATUS_PLAYING",
	ERROR: "SCENE_STATUS_ERROR",
};

export const CLIP_STATUS = {
	INIT: "CLIP_STATUS_INIT",
	READY: "CLIP_STATUS_READY",
	LOADING: "CLIP_STATUS_LOADING",
	FADE_IN: "CLIP_STATUS_FADING-IN",
	FADE_OUT: "CLIP_STATUS_FADING-OUT",
	PLAYING: "CLIP_STATUS_PLAYING",
	ERROR: "CLIP_STATUS_ERROR",
};

export const LOCAL_STORAGE_KEY = "SCENES";

export const VALID_SCENE_NAME = /^[a-zA-Z0-9,. !#&()/+-]+$/;
