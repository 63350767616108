import { css, keyframes } from "styled-components";

const rotate = keyframes`
	0% {
		transform: rotate(-15deg);
	}
	
	50% {
		transform: rotate(15deg);

	}

	100% {
		transform: rotate(-15deg);
	}
`;

const container = ({ theme }) =>
	css`
		display: flex;
		flex-direction: column;
	`;

const fallback = ({ theme }) => css`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: ${theme.constants.sidebar.mobile};
`;

const icon = ({ theme }) => css`
	font-size: 4em;
	animation: ${rotate} 5s infinite ease-in-out;
`;

const buttonContainer = ({ theme }) => css``;

const button = ({ theme }) => css`
	height: ${theme.space.xx};
	width: 100%;
	padding: 0;
	font-size: 1.5em;
`;

const styles = {
	container,
	fallback,
	icon,
	buttonContainer,
	button,
};

export default styles;
